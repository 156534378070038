var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"dashboard"}},[_vm._m(0),_c('div',{staticClass:"tab-slider--nav index-tab"},[_c('ul',{staticClass:"tab-slider--tabs group-info"},[_c('li',{staticClass:"tab-slider--trigger info-item",class:_vm.currentTab === 1 ? 'active' : '',attrs:{"rel":"tab1"},on:{"click":function($event){_vm.currentTab = 1}}},[_c('div',{staticClass:"tab-title"},[_c('div',{staticClass:"top-info"},[_vm._v("Average reply time to Client")]),_c('div',{staticClass:"bottom-info d-flex align-items-center"},[_vm._l((_vm.avgReplyTime),function(time,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.getSeconTime(time))+_vm._s(index === _vm.avgReplyTime.length - 1 ? "" : "/")+" ")])}),_c('span',{staticClass:"top-currency ml-10"},[_vm._v(" Hours")])],2)])]),_c('li',{staticClass:"tab-slider--trigger info-item",class:_vm.currentTab === 2 ? 'active' : '',attrs:{"rel":"tab2"},on:{"click":function($event){_vm.currentTab = 2}}},[_c('div',{staticClass:"tab-title"},[_c('div',{staticClass:"top-info"},[_vm._v("Total messages Client sent to bot")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"bottom-info col-9 d-flex align-items-center"},_vm._l((_vm.totalMessageSendToBot),function(time,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(time && time.value)+_vm._s(index === _vm.totalMessageSendToBot.length - 1 ? "" : "/")+" ")])}),0),_c('div',{staticClass:"bottom-info col info"},[_c('router-link',{attrs:{"to":{
                  name: 'Mention',
                  query: {
                    division: _vm.selectedDivison.length === 1 && _vm.selectedDivison[0] === 0 ? null : _vm.selectedDivison,
                    project: _vm.selectedProject.length === 1 && _vm.selectedProject[0] === 0 ? null : _vm.selectedProject,
                    date: _vm.$dayjs(_vm.filter.date).format('YYYY-MM-DD'),
                  },
                }}},[_c('button',{staticClass:"btn"},[_c('InfoIcon')],1)])],1)])])]),_c('li',{staticClass:"tab-slider--trigger info-item",class:_vm.currentTab === 3 ? 'active' : '',attrs:{"rel":"tab3"},on:{"click":function($event){_vm.currentTab = 3}}},[_c('div',{staticClass:"tab-title"},[_c('div',{staticClass:"top-info"},[_vm._v("Total messages not replied to Client")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"bottom-info col-9 d-flex align-items-center"},_vm._l((_vm.totalMessageNotReply),function(time,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(time && time.value)+_vm._s(index === _vm.totalMessageNotReply.length - 1 ? "" : "/")+" ")])}),0),_c('div',{staticClass:"bottom-info col info"},[_c('router-link',{attrs:{"to":{
                  name: 'NoReply',
                  query: {
                    division: _vm.selectedDivison.length === 1 && _vm.selectedDivison[0] === 0 ? null : _vm.selectedDivison,
                    project: _vm.selectedProject.length === 1 && _vm.selectedProject[0] === 0 ? null : _vm.selectedProject,
                    date: _vm.$dayjs(_vm.filter.date).format('YYYY-MM-DD'),
                  },
                }}},[_c('button',{staticClass:"btn"},[_c('InfoIcon')],1)])],1)])])])])]),_c('div',{staticClass:"heading mt-5"},[_c('div',{staticStyle:{"width":"30%"}}),_c('div',{staticClass:"right-heading",staticStyle:{"width":"70%","display":"flex","justify-content":"end"}},[_c('div',{staticClass:"input-group date"},[_c('vue-monthly-picker',{attrs:{"monthLabels":_vm.MONTH_LABEL,"placeHolder":"YYYY/MM","dateFormat":"YYYY/MM","alignment":"left","clearOption":false,"inputClass":"month-picker","max":_vm.maxMonth},on:{"selected":_vm.changeDate},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}}),_c('CalenderIcon')],1),_c('div',{staticClass:"input-group mx-3",staticStyle:{"justify-content":"end"}},[_c('el-select',{attrs:{"multiple":"","placeholder":"Division","collapse-tags":"true"},on:{"change":_vm.updateDivision},model:{value:(_vm.selectedDivison),callback:function ($$v) {_vm.selectedDivison=$$v},expression:"selectedDivison"}},_vm._l((_vm.divisions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"input-group",staticStyle:{"justify-content":"end"}},[_c('el-select',{attrs:{"multiple":"","placeholder":"Project","collapse-tags":"true"},on:{"change":_vm.update},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}},_vm._l((_vm.projects),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"chart-title"},[_vm._v(" "+_vm._s(_vm.currentTab === 1 ? "Hours" : "Message")+" ")]),_c('line-chart',{key:_vm.updateChart,attrs:{"data":_vm.chartData,"currentMonth":_vm.filter.date,"numberic":_vm.numberic,"currentTab":_vm.currentTab}}),_c('div',{staticClass:"chart-footer"},[_vm._v("Day")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading"},[_c('div',{staticClass:"left-heading"},[_c('h2',[_vm._v("Dashboard")])])])
}]

export { render, staticRenderFns }